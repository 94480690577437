// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/ITCKorinna.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/Swiss911.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/UniversBlack.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/SteileFuturaBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ITCKorinna";
  src: local("ITCKorinna"),
   url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Swiss911";
  src: local("Swiss911"),
   url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "UniversBlack";
  src: local("UniversBlack"),
   url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "SteileFuturaBold";
  src: local("SteileFuturaBold"),
   url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("opentype");
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;EACzB;6DAC+C;EAC/C,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB;6DAC6C;EAC7C,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B;6DACiD;EACjD,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B;6DACqD;EACrD,iBAAiB;AACnB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: \"ITCKorinna\";\n  src: local(\"ITCKorinna\"),\n   url(\"fonts/ITCKorinna.otf\") format(\"opentype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"Swiss911\";\n  src: local(\"Swiss911\"),\n   url(\"fonts/Swiss911.otf\") format(\"opentype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"UniversBlack\";\n  src: local(\"UniversBlack\"),\n   url(\"fonts/UniversBlack.otf\") format(\"opentype\");\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"SteileFuturaBold\";\n  src: local(\"SteileFuturaBold\"),\n   url(\"fonts/SteileFuturaBold.otf\") format(\"opentype\");\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

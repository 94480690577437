export const palette = {
    questionBackground: '#060ce9',
    questionText: '#cccccc',
    categoryText: '#daa520'
}

export const font = {
    question: 'ITCKorinna',
    category: 'Swiss911',
    value: 'Swiss911',
    score: 'UniversBlack',
    dailyDouble: 'SteileFuturaBold'
}